var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConsentWindow),expression:"showConsentWindow"}],staticClass:"py-3 px-4",style:({
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fff',
    borderRadius: '20px 20px 0 0 ',
    boxShadow: '0px 0px 25px #aaa',
    zIndex: 2,
  })},[_c('p',[_vm._v(" 🍪 Diese Webseite verwendet Matomo, um den Datenverkehr zu analysieren und uns dabei zu helfen, Ihr Nutzererlebnis zu verbessern. Wir verarbeiten Ihre IP-Adresse sowie Browser- und Geräteinformationen, und Cookies werden in Ihrem Browser gespeichert. Diese Daten werden nur von uns und unserer Webhosting-Plattform verarbeitet. ")]),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',[_c('b-button',{style:({ color: 'white' }),attrs:{"variant":"success","block":""},on:{"click":_vm.consent}},[_vm._v(" Einwilligen ")])],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"12","md":"auto"}},[_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.closeWithoutConsent}},[_vm._v(" Ablehnen ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }